import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/usr/src/app/www/theme/src/templates/single-post.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Toile d'araignée, radar, polaire ou Kiviat, il a beaucoup de noms.
Je remarque que ce diagramme exerce un pouvoir d'attraction très significatif. Beaucoup de personnes avec qui je discute (clients, élèves ou simples contacts) regarde ce diagramme avec beaucoup d'envie et de respect. Et quand vient le moment où l'on me demande mon avis, je suis embarrassé pour faire une réponse courte.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "650px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "26.993865030674847%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAFCAIAAADKYVtkAAAACXBIWXMAAAsSAAALEgHS3X78AAABHUlEQVQY0yWQzU/CMADF9/8fjDdjjIb4FeNF2IltTAIMQUwEowxkAoVuK6OjMNbSzW3UJt7eL3nJ+1DS3W7V6XhWK7SHRyFyzvFbz7eazPOEECnnoFIOR3Yu4XgshIBalUwm/6hQQjYArAFwHh8OB456r/6gjxECepVHEUvTye2N27ZYliVpivrv0/JTCOYHGZPnSsxYxOi3WkG2LfXSrAVhCDF2u93NbMaEAM2Go2vSDVutn/s7Ry0PLy8WhsYIUViSAMPwPgZ7RhnnbrMRTKckjoGhx0HAshwNv0alK5kcYbzfbhf1+ufpyVytUOQrsr3/0l6YtaWuURf+0titm/DZJCNb7kqyjEA4Pj+LQywfSYpiPR471yUaRRL/AK5rBwScsxjXAAAAAElFTkSuQmCC')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "2014",
          "title": "2014",
          "src": "/static/ecf07097d266f694ec08206069b7956e/a6d36/2014.png",
          "srcSet": ["/static/ecf07097d266f694ec08206069b7956e/222b7/2014.png 163w", "/static/ecf07097d266f694ec08206069b7956e/ff46a/2014.png 325w", "/static/ecf07097d266f694ec08206069b7956e/a6d36/2014.png 650w", "/static/ecf07097d266f694ec08206069b7956e/ef3e1/2014.png 759w"],
          "sizes": "(max-width: 650px) 100vw, 650px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <p><strong parentName="p">{`Bonne Année 2014`}</strong></p>
    <h3>{`Sa forme circulaire, sa fonction naturelle`}</h3>
    <p>{`Sa forme en cercle se prête naturellement à la représentation de données qui après avoir fait un tour complet reviennent ... à leur point de départ. `}</p>
    <p>{`Deux cas évidents d'application:`}</p>
    <ul>
      <li parentName="ul">{`l'espace: sources sonores, champs visuel, direction du vent, ...`}</li>
      <li parentName="ul">{`le temps et ses cycles: les heures de la journée, les jours de la semaine, les mois de l'année.`}</li>
    </ul>
    <p>{` `}<span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "650px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "68.09815950920245%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAOABQDASIAAhEBAxEB/8QAFgABAQEAAAAAAAAAAAAAAAAABAAD/8QAFgEBAQEAAAAAAAAAAAAAAAAAAQAC/9oADAMBAAIQAxAAAAFDDKw4WlH/xAAZEAADAQEBAAAAAAAAAAAAAAAAAQIDETH/2gAIAQEAAQUCnRs9GxY8lTxKVJ//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAEDAQE/AT//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAECAQE/AT//xAAaEAACAwEBAAAAAAAAAAAAAAAAIQECETJB/9oACAEBAAY/AqqMFXTkhkb4I//EABwQAQADAAIDAAAAAAAAAAAAAAEAESExQVFxkf/aAAgBAQABPyG/Ni7Qg0Gg93EvG+mNlYJ9g8k+MwzRzP/aAAwDAQACAAMAAAAQ8z//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAEDAQE/ED//xAAWEQADAAAAAAAAAAAAAAAAAAABEBH/2gAIAQIBAT8QgX//xAAcEAEBAAIDAQEAAAAAAAAAAAABEQAhMUFRcaH/2gAIAQEAAT8QHci2Orxc1sCgBebL8/cfYkNil7L91g5S4+w4cgqioVfMHbxq73n/2Q==')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "2017 11 06 19 58 03",
          "title": "2017 11 06 19 58 03",
          "src": "/static/d93f19cca5065ee6a71e645a3ac7c993/6aca1/2017-11-06-19.58.03.jpg",
          "srcSet": ["/static/d93f19cca5065ee6a71e645a3ac7c993/d2f63/2017-11-06-19.58.03.jpg 163w", "/static/d93f19cca5065ee6a71e645a3ac7c993/c989d/2017-11-06-19.58.03.jpg 325w", "/static/d93f19cca5065ee6a71e645a3ac7c993/6aca1/2017-11-06-19.58.03.jpg 650w", "/static/d93f19cca5065ee6a71e645a3ac7c993/7c09c/2017-11-06-19.58.03.jpg 975w", "/static/d93f19cca5065ee6a71e645a3ac7c993/01ab0/2017-11-06-19.58.03.jpg 1300w", "/static/d93f19cca5065ee6a71e645a3ac7c993/211c6/2017-11-06-19.58.03.jpg 1763w"],
          "sizes": "(max-width: 650px) 100vw, 650px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <h3>{`Sa capacité à visualiser plusieurs axes simultanément`}</h3>
    <p>{`Mais la plupart des utilisations de ce diagramme que l'on rencontre dans la nature exploitent des axes de catégories qui n'ont aucun ordre intrinsèque. `}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "543px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "54.601226993865026%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAIAAADwazoUAAAACXBIWXMAAAsTAAALEwEAmpwYAAABK0lEQVQoz32Rb0+DMBDG9/2/lL7HLHEJGQQt1GqhtKXQPwzoyjyZzmzinhdN7trf3T3XzWlNh2Fwh8Npnk93tfmbGr1/jiIcx1/BXX4FVkpFjw8kjtuyvM9fwXMIxjmcJrunqLO2SlNelscQ/oXnpXC9iHPOKK2zLEkSY4w15j2OK0qFlOWiaZpWOo/j6JyjVSUIKV9f0iwTnCuteVF8IKStBWwYBng5jKOUEiEEmd+xYYS2bcFwUxRvCNVCACMwrgiZf17A4bVWlKI8h36bCwmn1roBmHO63zNCOufqomgYu7HqvQdTIYSrhUEMxmTTGKiCMctzmiZ9112qr28bZrDWgm2AYXPgSipVYYy2WyUlXNzA5/B724AxxoQQfd9D5rgILibvz7+w2vkTWql6tqxW3o4AAAAASUVORK5CYII=')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "spiderSmall0",
          "title": "spiderSmall0",
          "src": "/static/a559722ce088842e28b8973a51bed7cd/29579/spiderSmall0.png",
          "srcSet": ["/static/a559722ce088842e28b8973a51bed7cd/222b7/spiderSmall0.png 163w", "/static/a559722ce088842e28b8973a51bed7cd/ff46a/spiderSmall0.png 325w", "/static/a559722ce088842e28b8973a51bed7cd/29579/spiderSmall0.png 543w"],
          "sizes": "(max-width: 543px) 100vw, 543px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <p>{`Le fait d'être côte à côte pour deux axes est totalement arbitraire. `}</p>
    <p>{`Voici 4 versions du même jeu de données:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "650px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "21.47239263803681%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAEABQDASIAAhEBAxEB/8QAFgABAQEAAAAAAAAAAAAAAAAAAAMF/8QAFAEBAAAAAAAAAAAAAAAAAAAAAf/aAAwDAQACEAMQAAAB2glAP//EABgQAAIDAAAAAAAAAAAAAAAAAAABAjFB/9oACAEBAAEFAmbGv//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQMBAT8BP//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQIBAT8BP//EABgQAAIDAAAAAAAAAAAAAAAAAAABEDFB/9oACAEBAAY/AmXkf//EABgQAAMBAQAAAAAAAAAAAAAAAAARIQEx/9oACAEBAAE/IWd6VqHE/9oADAMBAAIAAwAAABD0D//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQMBAT8QP//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQIBAT8QP//EABkQAQADAQEAAAAAAAAAAAAAAAEAETEhUf/aAAgBAQABPxDyoCi5h49bLXqqms//2Q==')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "spider0Small",
          "title": "spider0Small",
          "src": "/static/d3365fd37c721352a43e6dd2782f32c7/6aca1/spider0Small.jpg",
          "srcSet": ["/static/d3365fd37c721352a43e6dd2782f32c7/d2f63/spider0Small.jpg 163w", "/static/d3365fd37c721352a43e6dd2782f32c7/c989d/spider0Small.jpg 325w", "/static/d3365fd37c721352a43e6dd2782f32c7/6aca1/spider0Small.jpg 650w", "/static/d3365fd37c721352a43e6dd2782f32c7/22bc6/spider0Small.jpg 782w"],
          "sizes": "(max-width: 650px) 100vw, 650px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <p>{`Et l'ennui c'est que non seulement la forme mais aussi la `}<strong parentName="p">{`surface`}</strong>{` occupée par chacune de ces versions est différente.`}</p>
    <h3>{`L’ambigüité surface/ distance`}</h3>
    <p>{`La rose de `}<a parentName="p" {...{
        "href": "http://en.wikipedia.org/wiki/Florence_Nightingale"
      }}>{`Florence Nightingale`}</a>{` montre l'origine des décès des soldats lors de la guerre de Crimée de 1854. La surface rouge les blessures, bleue une maladie contagieuse et noire non classée. On comprend bien à la vue de ce diagramme que les ennemis les plus féroces sont le typhus et le choléra.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "306px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "114.11042944785277%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAXCAIAAACEf/j0AAAACXBIWXMAAFwfAABcHwFUbPxjAAAEBElEQVQ4y31UWW8bVRT2r4SHCh5oJVCBVkGgoojSIlokXnhpS4UAFbEkdmyPPTOezbOvnsUz8RJnw4njOE5iO95iO97GM9wxoiKCdHQeRvfe757vfOd+J+RPF28Ib+L6U8+f+/7M86aLs9Nmr9UdX46Cleki9CbYzPPB73B68Gel1+r0O0PZzOl2YTObm/RHYPdGMIBd9UfdxkW5XMUFfbO45xT3ZLMgWzkrvzMejH3XD92Udti5BAxPKseabnOao5p52corVkHUN0UjV6ueXvWG/wFPFqCe6WjSqJ35nn81GGm6Y26WMtk8q2YZ1WZVh1GDu+qV43/AoLy/Y+65E/f8+HQ2ngJi4FMUPRxHSEEnRZMQDUI0McEQjXzByQfgxXi+mLgg3HGg7ag3MlWj1+yAu1iKebDyCfTiBfJ7OEGJoHiApxU7LVlHh7UQ4HZ4VGckEzCRDEcybNkw1yPhb79Yff71V+kfn6V/eF4jsYMUSkYTSUaDKCD57m5pv1o+CnnjmZXNQwSHsQopqKQgETz36/pvqx/fvffuLfWXl22OqKfxAxgiIvEwLoQx3nRKnfNm0Cp3NC0VdyhRJ3mVEhRGVrE09fTxl6sf3V25c/vpyn3555fqq5+2YtETikrBJC5nLcO5bHWXrVr41cMqK+mMbKZFDecEGMe/efTw0zvvffjOrdtvv/X5Zw/WYnAWI22MRFkVF4xccW8C+hw8EtfvN9uZTJZWLFLUME5IEngUhp48efzo4er9D97/7tn3nJWP4ixESYRgwoyWYLSDw5MleO4PekPD2SKCZmRISUmxDExiCIEgBAoTBMGJYB3lNEq2WG2TlG3VKu5t7fgTN1StnlKSgfEZSgJtUFO8iNBpmECTWDKOQDhDE4LMyAY4Q0omr+eAWrZhD7uXIGuo1ewuW68DzRBWQjkRIolYKhFD4jEEQtNUihMoKYOLmRSfyZfKo+5g2BsApYLM4IVYdgETMpSso7wMM1ycwDZQKJyIROE4nKZQVsBFLcVrOMisOf0lcmnVpSXrlZogZ1BBTbJiggbCEFE0uZ6IxFMJjKVxXiSlDMKpEC1BacnM7bw2wvJtu379qLazW6YUA6JZUHMcgzeQOADjLI0yHMorKVHHJV0y82dnF4F5XoMBB28GqPhOaQ+Ak3R6A4MjCAQiSRJ2Yau4vc+Lata0G/VzcMybXgcD6RqNdphkIZpDaDqSgv9IxMJIMkYQzVbbHc/6zc50MPaWw+gabW9pyavBOLe9j4sKRFHrSOJVdH0NhiIY1jy/CBQCQ2vue9fN/69hAMzs+ZVyxco6rCzFMHgtGV9D4FbjIoAt5fVvBC8DcBv1hsBu24XS8dFxu9meDifBvf83rf4CuJR/xCzvNjcAAAAASUVORK5CYII=')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "NightingaleSmall1",
          "title": "NightingaleSmall1",
          "src": "/static/6c963c984dc0ed631536d32d11f4e632/98b92/NightingaleSmall1.png",
          "srcSet": ["/static/6c963c984dc0ed631536d32d11f4e632/222b7/NightingaleSmall1.png 163w", "/static/6c963c984dc0ed631536d32d11f4e632/98b92/NightingaleSmall1.png 306w"],
          "sizes": "(max-width: 306px) 100vw, 306px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <p>{`Ce n'est pas évident au premier coup d'`}{`œ`}{`il mais il y a deux manières distinctes de lire le diagramme de Nigthtingale:`}</p>
    <ul>
      <li parentName="ul">{`la distance du périmètre externe de la tranche au centre`}</li>
      <li parentName="ul">{`la surface de la tranche (ou de la portion de tranche)`}</li>
    </ul>
    <p>{`Et ce n'est pas la même chose ! `}</p>
    <p>{`Quand le rayon de la tranche est multiplié par 2, la surface de celle ci est multipliée par 4. La vaste majorité des cobayes à qui on demande de retrouver les valeurs des données à partir du diagramme de Nightingale, mesurent les rayons alors que ce sont les surfaces qui encodent les données.`}</p>
    <p>{`Y compris pour les représentations filaires pour lesquelles il semble clair que c'est la distance au centre qui est la donnée, on ne peut s'empêcher de voir la surface que forme l'angle avec la ligne qui joint les points de mesure successifs. Surface de forme aussi arbitraire que l'ordre choisi pour les axes!!!`}</p>
    <h3>{`La comparaison sur plusieurs axes`}</h3>
    <h4>{`Un diagramme pour  tous`}</h4>
    <p>{`A condition d'utiliser une représentation filaire (pas comme celle de Nightingale), il est possible de mettre plusieurs entités sur un même diagramme et de les comparer. A un objectif ou entre elles.
La convention habituelle est que le centre est la note la plus faible, la périphérie la plus haute.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "333px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "65.03067484662577%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANCAIAAAAmMtkJAAAACXBIWXMAAAsSAAALEgHS3X78AAAB3klEQVQoz3VSa4+aQBT1//+Ipkm/NU0/bG22aeK61Uar2cWlK7CIAiNvYRCYgYHhYQcfpDX2hEzInXvuuXPu7R3+RV1XCCUkpRkmSRxVVc2CTdNeQVzKDnZt2zLM/T5mkd7x7oia5TUlLU3D5F7H3O8RULeEkLZiVbIT76AnKLZle47r+wEty96VcpaHPlLMnSSDKSL+qTQ72E+Z7uFWXMkrXdUkQYqSpCVTWkA/CKDnI9VK1jlFrwA9Ll0z4IIE5jCKgA7D2HQ3IBAtw3Id17YslKCWnKV4q215acov++GLEFnW6H48vhuq3GLx8G718YMyGKjAMHQjS0lnwfnNh0tAX3PS/fvhgBcmc2M2nXydrLi+C7Wc1uesiz8nXAyr65xSEsDZ0/DnWrBnz9LdF170ArQNY7cVbJqqqppO9G/lUxQo6uzTN2Hx+e2pr6nigNfXxjxMnJwUh1voEZLFUZRhpDnR9yHv/HjIcG7vlYn4uFAEGYwsVw3hHiPUfjg9Tf5M9neeuBQMAJZvgNvAzY4lpAXGC9lxA1SQnI1653krSV7LMlsBWtDrtrvmTR+/sBIW/CWahKRFcbvhM/nkW7dnR0sPw2d1vtS7qVz5dFv5uNttHnsFULX6P5wOfwDhEtoL8WHO6gAAAABJRU5ErkJggg==')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "spider3 FOFTQKPYETMF1YERWZIS",
          "title": "spider3 FOFTQKPYETMF1YERWZIS",
          "src": "/static/990fec6f44b3d7e82a610feae8ef7184/24c7e/spider3_FOFTQKPYETMF1YERWZIS.png",
          "srcSet": ["/static/990fec6f44b3d7e82a610feae8ef7184/222b7/spider3_FOFTQKPYETMF1YERWZIS.png 163w", "/static/990fec6f44b3d7e82a610feae8ef7184/ff46a/spider3_FOFTQKPYETMF1YERWZIS.png 325w", "/static/990fec6f44b3d7e82a610feae8ef7184/24c7e/spider3_FOFTQKPYETMF1YERWZIS.png 333w"],
          "sizes": "(max-width: 333px) 100vw, 333px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <h3>{`Les concurrent les plus directs du diagramme araignée`}</h3>
    <p>{`Il existe des alternatives au diagramme de Kiviat quand il est utilisé pour comparer selon plusieurs axes. Voici les deux les plus immédiates:`}</p>
    <ul>
      <li parentName="ul">{`le `}<em parentName="li">{`diagramme en points`}</em>{` ou le `}<em parentName="li">{`dot plot`}</em></li>
    </ul>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "340px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "42.94478527607362%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAJCAIAAAC9o5sfAAAACXBIWXMAAAsSAAALEgHS3X78AAABeklEQVQoz2VRyW7bMBDV/6OHAv2DfkJvRa9BYDgN0rR27MiKRS2kFooSqZWrmHF8qZEHkBg88s28mQn8LdYr/mfc5S6FfIzb1eplWcZxvH4JkgRlgBzH8bmsa6UU0KbA01uEMkqq0XppfPb3Nf3+44m3FGOSJAmkuIjVsiCE0jSFDHXTaGuBrdgQ4y47hOT3T/b4i7/+GTb31bevzTw3dc0Yo5Q65wLBOUqSsoSqwNC5Ya4snh6OdUQUOQzJvc2J56pl25f9F0JajLM8z6FS3/dBQylEKD5HCBVhKLYP0Hfd8ig/HfOXqMiF9qP2Sg9SlVoZBccYrcGiDa6td4xlGHNKh9NJ4ULEd1qkThp47Aa5S1g/q49x3kw3GHqx3+3ACoyhbhhk9c731bOIN9Xzpjz8Y7PZp2Jc9EV8u5pLZWtNWYDlEBMCfj4Ws5z5/BYdw+1dLRDnR2ul/4QArK+rm6aRd900zSB2zi5SSalXt3q3Ome0mtxqP4vfAU3/+XgVSvoGAAAAAElFTkSuQmCC')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "spider4 UPTUA4TATR3HCVXSSORJ",
          "title": "spider4 UPTUA4TATR3HCVXSSORJ",
          "src": "/static/44d617fdd1404407a4f1d4b07bbbffad/9f933/spider4_UPTUA4TATR3HCVXSSORJ.png",
          "srcSet": ["/static/44d617fdd1404407a4f1d4b07bbbffad/222b7/spider4_UPTUA4TATR3HCVXSSORJ.png 163w", "/static/44d617fdd1404407a4f1d4b07bbbffad/ff46a/spider4_UPTUA4TATR3HCVXSSORJ.png 325w", "/static/44d617fdd1404407a4f1d4b07bbbffad/9f933/spider4_UPTUA4TATR3HCVXSSORJ.png 340w"],
          "sizes": "(max-width: 340px) 100vw, 340px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <ul>
      <li parentName="ul">{`la `}<em parentName="li">{`carte de score`}</em>{` ou la `}<em parentName="li">{`punch card`}</em></li>
    </ul>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "256px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "60.122699386503065%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAMCAIAAADtbgqsAAAACXBIWXMAAAsSAAALEgHS3X78AAAB5klEQVQoz21SuY7iQBDlQ+En4AdIECJCQkgkRISEREQMrLgTkAYsGNvt9oXxfV+YY6unRzsjzXbQeqqqV9XvVZee/zuv5zPLsjiOwzAs7vfH43HVNN/zX69XnmUYY0gBLpHSXweCiixblslxvO/7tm3O/sxOZxZSoog36/Xp9EHIYeDLkiRiUZIkWRJ5hDmO83wypNfrbbdbAGmarlYrhBBgx3Wr1erxeCTkW567jqPrhm4YpqEDuF6vaZpBbjgcHg4H+pbRaMTzPIAoiprNpiAIhExzWRx+sCyPBKgQEPKDCHQul0tFUWhBuVxeLBYAPM/rdru0ESHneSZiSbuoF00zDF1VL1EUg+y3tyl4Qy1gGMY0TcBgYafToRIIubjliEeKLHE8Dx1kWQ7DCOKz2UwURUoGI2zbpuR+v0/jpX/2wh34wYlhEJaiT3KtVhuPx6R7UYDnQKPmrddry7K+yY5t64bpuo6iqqAzThIItlqt+XxOCwaDAcuSVSVJMp1OqYSvPVuG/v5+wFjAWELEMDKkXq9PJhNKbrfb1Hn4Ht/kn892bQsh4Xw+eZ7/eNzBXtBPCxqNxn6/J9KCoFKpfO35/nmK4gbCoig0LQvWnqQpBOHbwGKgL+DdbkenwbfdbDaO4wD+C65Ei7uhMtaFAAAAAElFTkSuQmCC')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "spider5 A8IMBUO8JDFA7QPEBNR9",
          "title": "spider5 A8IMBUO8JDFA7QPEBNR9",
          "src": "/static/eb6360cf94f30072439bf7980dc18af0/6f3f2/spider5_A8IMBUO8JDFA7QPEBNR9.png",
          "srcSet": ["/static/eb6360cf94f30072439bf7980dc18af0/222b7/spider5_A8IMBUO8JDFA7QPEBNR9.png 163w", "/static/eb6360cf94f30072439bf7980dc18af0/6f3f2/spider5_A8IMBUO8JDFA7QPEBNR9.png 256w"],
          "sizes": "(max-width: 256px) 100vw, 256px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <h4>{`Un diagramme pour chacun`}</h4>
    <p>{`C'est l'utilisation faite par une célèbre marque de comparatifs de produits techniques. `}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "551px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "124.53987730061348%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAZCAIAAAC+dZmEAAAACXBIWXMAAAsSAAALEgHS3X78AAADIElEQVQ4y21Ua5OiMBD0//+qq7oP6613nuuirigsCBgSXgnh/dBrCFLu1uWDBRl7pqenh0UURefzOcuysizTNL3dbvf7vZKyq+v746jLPM99n/TjszqLuq6v12vXdUmSGIbRti1uU8ZC30+lzLjAa1GUQcBcj2rbEyWU86Tv+wE8J2aM7ff7rm1j287LEnVklskwvPV9ludxHK9XmmFYq7VOCO3GGgsgVRqETdPEraAsct37gx6inPMsz8wPY7fZnyxCr7Suq6myOkVRoH+VqEiSrmlmUjhQBNmbpmGUop2p5zAM0TNyIyylVP8u66aq6hmsfiFHnsnnmwXybTYb6Iyyuq4rwZqmDaPkSvwsLxUsipKTTf9o5wuJRJpOlec0KG7bNmSPEhEEAWVBPiIRvHWtbpHt0Toa9nJrCiEmtWfBwF/TNHSF13Y8HmGpzIfJCW57bLWzfr3pB8OL46gsvwoGn6CgSjRJWFZiBA/1+w74RGRRyDDCiXZVVagJq4ACON+eDDQ2X4LZw2dd2xTP8QUKvr6+QmpK6fv7O/Aq0HUNISbnZEzRFEV+pcGP1TpM4rIoJrVnwVAfao8+daRMwUhKDpRSG/bcHj9M290edT569otgoOA4zjDMwYsx9C4KPjks4T6j67223r3tjU8WwGH191EBPCbqs2xQoW2ruUMQgZww/NXzMKqpMlyJbtHztwVElm/i9ZBhsPTTSmIZlsul67pw2PF4VA4b9oSHLGBgqHoOo/DskPVed2koBJ/AcwWs5G63g2AiTdAzF/HIvFE0LI9+GI7jea/aKZkFm3liK5RgVVVALTRZ5EUqObLLNL1c6Va3lpvt+eKmQiD6BYwvCcBPcx4ewoh23dAIWHjEF6n0CfkiGHRGDBWUzx7fgFtVNc+CoQUs37OQC0LIy8sLyiJwOBwUH6W2lLllkbbt8Ex8uj1efq7ex8VI/rOSAIPIXCqOxafpDdFbb1x83XSSkGq67Qfxd4cBjFHNYLCD5KbpNU2HD+HnhfzWjINu/D3Yruc3s8NmD2FCs2DqSFmo1JDN8ciny06mnefTSv4D8FCUzWKuvCwAAAAASUVORK5CYII=')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "compare",
          "title": "compare",
          "src": "/static/ad65fa6fea58ed856637b1f540d987ae/db783/compare.png",
          "srcSet": ["/static/ad65fa6fea58ed856637b1f540d987ae/222b7/compare.png 163w", "/static/ad65fa6fea58ed856637b1f540d987ae/ff46a/compare.png 325w", "/static/ad65fa6fea58ed856637b1f540d987ae/db783/compare.png 551w"],
          "sizes": "(max-width: 551px) 100vw, 551px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <p>{`Le diagramme radar a des atouts certains dans cet usage. Le regard peut identifier et reconnaitre efficacement des `}<strong parentName="p">{`profils`}</strong>{`. Les grands nez, les petites oreilles, ... Enfin l'ensemble des caractéristiques désirables ou indésirables parmi un grand nombre de schémas.`}</p>
    <p>{`L'appareil Sony fournit un bon exemple de l'erreur de perception surface/distance. Sur la version radar l'appareil est quasiment parfait (surface maximale de la zone). Le diagramme en points (dotplot) montre de manière plus objective ses caractéristiques. `}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      